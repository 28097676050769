import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/auth/Login'
import Base from '../layouts/Base'
import BaseLogin from '../layouts/BaseLogin'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: { layout: BaseLogin }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    meta: { layout: Base }
  },
  {
    path: '/patients',
    name: 'patientOverview',
    component: () => import(/* webpackChunkName: "patientoverview" */ '../views/patients/PatientOverview.vue'),
    meta: { layout: Base }
  },
  {
    path: '/patient/:patientId',
    name: 'patient',
    component: () => import(/* webpackChunkName: "patient" */ '../views/patients/Patient.vue'),
    meta: { layout: Base }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Terms.vue'),
    meta: { layout: BaseLogin }
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue'),
    meta: { layout: BaseLogin }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
