import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/sass/style.scss'
import jQuery from 'jquery'
import axios from 'axios'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEdit, faAddressBook } from '@fortawesome/free-regular-svg-icons'
import { faPhoneAlt, faEnvelope, faChevronDown, faChevronUp, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import 'bootstrap/dist/js/bootstrap'

Vue.config.productionTip = false

// Global variables
Vue.prototype.$http = axios
window.$ = jQuery

// Font Awesome
library.add(faEdit, faAddressBook, faEnvelope, faPhoneAlt, faChevronDown, faChevronUp, faChevronLeft)
Vue.component('font-awesome-icon', FontAwesomeIcon)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
