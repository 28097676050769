<template>
  <div class="d-flex flex-column">
    <div class="spacer"></div>

    <div id="login-page">
      <div id="left" :style="`background-image:url(${require('@/assets/img/login_bg_generic.jpg')})`"></div>

      <div id="right">
        <h1 class="title h2 font-weight-normal">Zorgverlenersportaal</h1>

        <form method="POST" action="#" class="d-flex flex-column">

          <div class="flex-fill">
            <div class="form-group">
              <label for="email" class="font-weight-medium">E-mailadres</label>
              <input id="email" type="email" class="form-control" :class="{ 'is-invalid': error }" name="email" required autofocus autocomplete="email">

              <span v-if="error" class="invalid-feedback" role="alert">
                <strong>[[error message]]</strong>
              </span>
            </div>
            <div class="form-group">
              <label for="password" class="font-weight-medium">Wachtwoord</label>
              <input id="password" type="password" class="form-control" :class="{ 'is-invalid': error }" name="password" required autocomplete="current-password">

              <span v-if="error" class="invalid-feedback" role="alert">
                <strong>[[error message]]</strong>
              </span>

              <small class="form-text">
                <a href="#">Wachtwoord vergeten?</a>
              </small>
            </div>
          </div>
          <div class="mt-3">
            <router-link to="/dashboard" class="btn btn-primary btn-block">Inloggen</router-link>
  <!--            <button type="submit" class="btn btn-primary btn-block">Inloggen</button>-->
          </div>
        </form>
      </div>
    </div>

    <div id="footer-links" class="mt-4 mb-3">
      <span style="font-size: 0.8rem;">
        <router-link to="/terms" class="text-reset" >Voorwaarden</router-link> |
        <router-link to="/privacy" class="text-reset" >Privacy</router-link>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      error: false
    }
  }
}
</script>

<style lang="scss">
.spacer {
  flex: 1 1 0;
}

#footer-links {
  text-align: center;
  color: #fff;
  flex: 1 1 0;
}
</style>
