<template>
  <div id="base-page">
    <app-sidebar></app-sidebar>

    <div class="flex-column flex-fill pl-4">
      <slot/>
    </div>
  </div>
</template>

<script>
import AppSidebar from '../components/layout/AppSidebar'

export default {
  name: 'Base',
  components: { AppSidebar }
}
</script>

<style lang="scss">
#app {
  background-color: #F2F6FE;
}

#base-page {
  max-width: 1140px;
  margin: 0 auto;
  width: 100%;
}
</style>
