<template>
  <div id="sidebar">
    <div id="sidebar-top">
      <div id="logo">
        <img src="../../assets/img/mnnu_logo_compact_text.svg">
      </div>
    </div>
    <nav id="sidebar-nav">
      <ul class="nav">
        <li class="nav-item">
          <router-link to="/dashboard" class="nav-link">
            <div class="svg-icon nav-icon">
              <svg viewBox="0 0 16 16" preserveAspectRatio="xMidYMid meet" focusable="false" style="pointer-events: none; display: block; width: 100%; height: 100%;"><g><path d="M8,.5l8,8.05-.77.77-.7-.7V15.5H9.09V10H6.91V15.5H1.47V8.62l-.7.7L0,8.55Zm5.44,13.91V7.53L8,2.06,2.56,7.53v6.88H5.82V8.94h4.36v5.47Z"></path></g></svg>
            </div>
            <span>Dashboard</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/patients" class="nav-link">
            <div class="svg-icon nav-icon">
              <font-awesome-icon :icon="['far', 'address-book']" size="2x"/>
            </div>
            <span>Patiënten</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/" class="nav-link">
            <div class="svg-icon nav-icon">
              <svg viewBox="0 0 16 16" preserveAspectRatio="xMidYMid meet" focusable="false" style="pointer-events: none; display: block; width: 100%; height: 100%;"><g><path d="M8,.5l8,8.05-.77.77-.7-.7V15.5H9.09V10H6.91V15.5H1.47V8.62l-.7.7L0,8.55Zm5.44,13.91V7.53L8,2.06,2.56,7.53v6.88H5.82V8.94h4.36v5.47Z"></path></g></svg>
            </div>
            <span>Pagina 3</span>
          </router-link>
        </li>
      </ul>
    </nav>
    <div id="sidebar-bottom">
      <div class="profile">
          <img class="profile-picture" src="https://randomuser.me/api/portraits/women/79.jpg">
          <span>Nienke Kortman</span>
      </div>
      <ul class="nav justify-content-around">
        <li>
          <router-link to="/" class="d-flex">
            <div class="nav-icon">
              <svg viewBox="0 0 16 16" preserveAspectRatio="xMidYMid meet" focusable="false" style="pointer-events: none; display: block; width: 100%; height: 100%;"><g><path d="M8,.5l8,8.05-.77.77-.7-.7V15.5H9.09V10H6.91V15.5H1.47V8.62l-.7.7L0,8.55Zm5.44,13.91V7.53L8,2.06,2.56,7.53v6.88H5.82V8.94h4.36v5.47Z"></path></g></svg>
            </div>
          </router-link>
        </li>
        <li>
          <router-link to="/" class="d-flex">
            <div class="nav-icon">
              <svg viewBox="0 0 16 16" preserveAspectRatio="xMidYMid meet" focusable="false" style="pointer-events: none; display: block; width: 100%; height: 100%;"><g><path d="M8,.5l8,8.05-.77.77-.7-.7V15.5H9.09V10H6.91V15.5H1.47V8.62l-.7.7L0,8.55Zm5.44,13.91V7.53L8,2.06,2.56,7.53v6.88H5.82V8.94h4.36v5.47Z"></path></g></svg>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppSidebar'
}
</script>
